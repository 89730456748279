import { v4 as uuid } from 'uuid'
import CryptoJS from 'crypto-js'

export const ComfyUIMessageType = {
  LOGOUT: 'LOGOUT',
  LOGIN_REQUIRED: 'LOGIN_REQUIRED',
  RELOAD: 'RELOAD',
  ACK: 'ACK',
  READY: 'READY',
  NODE_CHANGED: 'NODE_CHANGED',
  DOWNLOAD_JSON: 'DOWNLOAD_JSON',
  SAVE: 'SAVE',
  SAVE_AS: 'SAVE_AS',
  RENAME: 'RENAME',
  PROMPT: 'PROMPT',
  CHANGE_WORKFLOW: 'CHANGE_WORKFLOW',
  DELETE_WORKFLOW: 'DELETE_WORKFLOW',
  UPDATE_PROMPT_ID: 'UPDATE_PROMPT_ID',
  PROMPT_ERROR: 'PROMPT_ERROR',

  TASK_STATUS: 'TASK_STATUS',
  GET_WORKFLOW_INFO: 'GET_WORKFLOW_INFO',
  DOWNLOAD_IMAGE: 'DOWNLOAD_IMAGE',

  EXPORT_JSON: 'EXPORT_JSON',
  IMPORT_FILE: 'IMPORT_FILE',
  IMPORT_JSON: 'IMPORT_JSON',
  RESET_NODE: 'RESET_NODE',
  FETCH: 'FETCH',
  FETCH_RESPONSE: 'FETCH_RESPONSE',
  UPLOAD_FILE: 'UPLOAD_FILE',
  UPLOAD_FILE_RESPONSE: 'UPLOAD_FILE_RESPONSE'
}

const RedirectOssUrlPrefix = location.origin + '/api/comfyui/view'

export interface KerquMessage {
  id: string
  type: string
  payload: any
}

export const sendMessageToParent = (message: KerquMessage) => {
  if (window.parent) {
    window.parent.postMessage(JSON.stringify(message), '*')
  }
}
export const sendKerquMessageAndWaitAck = async (
  message: KerquMessage,
  timeout = 15000
) => {
  return new Promise<KerquMessage>((resolve, reject) => {
    const id = message.id || uuid()
    let timer: NodeJS.Timeout | undefined = undefined
    sendMessageToParent(message)
    const handler = (event: MessageEvent) => {
      if (event.source === window.parent && window.self !== window.top) {
        const data = JSON.parse(event.data)
        if (data.type === ComfyUIMessageType.ACK && data.id === id) {
          if (timer) {
            clearTimeout(timer)
          }
          window.removeEventListener('message', handler)
          resolve(data as KerquMessage)
        }
      }
    }
    window.addEventListener('message', handler)
    timer = setTimeout(() => {
      reject('timeout')
      window.removeEventListener('message', handler)
      timer = undefined
    }, timeout)
  })
}

export const fetchServer: (payload: {
  url: string
  data?: any
  method: string
}) => Promise<any> = (payload) => {
  return new Promise((resolve, reject) => {
    const id = uuid()
    const handler = (event: MessageEvent) => {
      if (event.source === window.parent && window.self !== window.top) {
        const data = JSON.parse(event.data)
        if (data.type === ComfyUIMessageType.ACK && data.id === id) {
          window.removeEventListener('message', handler)
          if (data.payload.error) {
            reject(data.payload.error)
          } else {
            resolve(data.payload.data)
          }
        }
      }
    }
    sendMessageToParent({
      id,
      type: ComfyUIMessageType.FETCH,
      payload
    })
    window.addEventListener('message', handler)
  })
}

export interface UploadFileResponse {
  ossKey?: string
  id: string
  error?: any
}

export const uploadOssFile: (payload: {
  file?: Blob
  dataUrl?: string
}) => Promise<UploadFileResponse> = async (payload) => {
  const dataUrl = payload.file
    ? await fileToDataUrl(payload.file)
    : (payload.dataUrl as string)
  const res = await sendKerquMessageAndWaitAck({
    id: uuid(),
    type: ComfyUIMessageType.UPLOAD_FILE,
    payload: {
      dataUrl
    }
  })
  return res.payload
}

export function dataURItoBlob(dataURI: string) {
  // 移除 data: 协议部分
  const byteString = atob(dataURI.split(',')[1])

  // 获取 MIME 类型
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // 创建一个 ArrayBuffer
  const ab = new ArrayBuffer(byteString.length)

  // 创建一个视图来操作 ArrayBuffer
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  // 创建 Blob
  return new Blob([ab], { type: mimeString })
}

export const fileToDataUrl: (file: Blob) => Promise<string> = (file: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function (e) {
      resolve(e.target?.result as string)
    }
    reader.onerror = function (e) {
      reject(e)
    }
    reader.readAsDataURL(file)
  })
}

export const getOssKey = (url: string) => {
  try {
    return decodeURIComponent(_getOssKey(url))
  } catch (e) {
    console.error(e)
    return ''
  }
}

export const _getOssKey = (url: string) => {
  if (url === undefined || url === 'undefined') {
    return ''
  }
  if (import.meta.env.DEV) {
    if (url.startsWith('/upload/')) {
      return url.substring(8)
    }
  }
  if (url.startsWith(RedirectOssUrlPrefix)) {
    const searchParams = new URLSearchParams(
      url.substring(RedirectOssUrlPrefix.length)
    )
    return searchParams.get('filename') as string
  }
  if (url.startsWith(import.meta.env.VITE_PUBLIC_OSS_HOST)) {
    let start = url.indexOf('/temp/')
    if (start === -1) {
      start = url.indexOf('/image/')
    }
    const end = url.indexOf('?')
    return url.substring(start + 1, end)
  } else if (url.startsWith('temp/') || url.startsWith('image/')) {
    return url
  } else if (url.startsWith('/temp/') || url.startsWith('/image/')) {
    return url.substring(1)
  } else if (url.startsWith('group/')) {
    return url
  } else if (url.startsWith('/group/')) {
    return url.substring(1)
  }
  throw new Error('invalid oss key: ' + url)
}

export const OSS_RESIZE_MODE = {
  FILL: 'm_fill',
  CONTAIN: 'm_pad',
  FIT: 'm_lfit'
}

export const getOssRedirectUrl: (
  key: string,
  options?: { width?: number; height?: number; mode?: string }
) => string = (key, options) => {
  const { width, height, mode } = options || {}
  if (!key) return ''
  const ossKey = getOssKey(key)
  const searchParams = new URLSearchParams()
  searchParams.set('filename', ossKey)
  if (options) {
    const params = ['image/resize']
    if (mode) {
      params.push(mode)
    } else {
      params.push(OSS_RESIZE_MODE.FIT)
    }
    if (width) params.push(`w_${Math.ceil(width)}`)
    if (height) params.push(`h_${Math.ceil(height)}`)
    searchParams.set('originalImage', 'false')
    searchParams.set('process', encodeURIComponent(params.join(',')))
  } else {
    searchParams.set('originalImage', 'true')
  }
  return RedirectOssUrlPrefix + '?' + searchParams.toString()
}

export const isOssKey = (url: string) => {
  if (!url || typeof url !== 'string') return false
  if (import.meta.env.DEV) {
    if (url.startsWith('/upload/')) {
      return true
    }
  }

  return (
    url.startsWith(location.origin + '/api/comfyui/view?') ||
    url.startsWith('/api/comfyui/view?') ||
    (url.startsWith(import.meta.env.VITE_PUBLIC_OSS_HOST) &&
      url.indexOf('/static/') === -1) ||
    url.startsWith('temp/') ||
    url.startsWith('/temp/') ||
    url.startsWith('group/') ||
    url.startsWith('/group/') ||
    url.startsWith('image/') ||
    url.startsWith('/image/')
  )
}

export const getFileNameOfPath = (path: string) => {
  return path.replace(/^workflows/, '').replace(/\.json$/, '')
}

export interface ModelTreeNode {
  id: number
  name: string
  sub: string[]
}

export const getModelTree = async () => {
  const res = await fetchServer({
    url: '/api/comfy/models',
    method: 'get'
  })
  return res.data as ModelTreeNode[]
}

interface OssCachedItem {
  value?: string
  expire?: number
  promise: Promise<string> | null
}

const cachedOssKeys = new Map<string, OssCachedItem>()

export const getImagePreviewUrl: (url: string) => Promise<string> = async (
  url
) => {
  if (!isOssKey(url)) return ''
  const item = cachedOssKeys.get(url)
  if (!item?.expire || item.expire < Date.now() + 10000) {
    const prom = fetchServer({
      url: '/api/aiImage/preview',
      method: 'post',
      data: {
        imageList: [getOssKey(url)],
        originalImage: true
      }
    }).then((res) => {
      const ossUrl = res.data.images[0] as string
      const expire =
        parseInt(new URL(ossUrl).searchParams.get('Expires') || '0', 10) * 1000
      cachedOssKeys.set(url, {
        value: ossUrl,
        expire,
        promise: null
      })
      return ossUrl
    })

    cachedOssKeys.set(url, {
      promise: prom
    })
    return prom
  }
  if (item.value) {
    return item.value
  }
  if (item.promise) {
    return new Promise((resolve) => {
      item.promise = item.promise!.then((url) => {
        resolve(url)
        return url
      })
    })
  }
  return ''
}

export const getImagePathObj = (ossKey: string) => {
  return {
    filename: ossKey,
    name: ossKey,
    type: 'input',
    subfolder: ''
  }
}

const secretKey = '2405f687-8414-4efa-8b84-54f922d316ac'

export const encryptData = (data: string) => {
  const encrypted = CryptoJS.AES.encrypt(data, secretKey)

  return encrypted.toString()
}

export const decryptData = (encryptedData: string) => {
  const decrypted = CryptoJS.AES.decrypt(encryptedData, secretKey)

  return decrypted.toString(CryptoJS.enc.Utf8)
}

export const getTempOssUrl = async (key: string) => {
  const res = await fetchServer({
    url: '/api/aiImage/download',
    method: 'POST',
    data: {
      imageList: [getOssKey(key)]
    }
  })
  return res.data.images[0]
}

export const downloadKerquImage = (img: HTMLImageElement) => {
  console.log('download ', img.src)
  if (isOssKey(img.src)) {
    sendMessageToParent({
      type: ComfyUIMessageType.DOWNLOAD_IMAGE,
      id: uuid(),
      payload: {
        images: [getOssKey(img.src)]
      }
    })
    return true
  }
}
