const NODE_ENV = import.meta.env.NODE_ENV === 'development'
const domain = NODE_ENV ? location.hostname : '.' + location.hostname

const namePrefix = ''

const LOGIN_COOKIE = ['groupId', 'kerqu-trader-token']
const OFFICAL_COOKIE_PREFIX = 'kerqu_offical_'
const OFFICAL_WEBSITE = location.hostname.replace('ai', '') //"kerqu.com";

export const myCookie = {
  get: (name: string) => {
    const cookieString = document.cookie
    // console.log('cookieString',name, '-----------', cookieString)
    const cookies = cookieString.split('; ')
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].split('=')
      if (cookie[0] === name + namePrefix) {
        // console.log('name:',cookie[1])
        return cookie[1]
      }
    }
    return null
  },
  set: (name: string, value: string, time: number, domainHost: string) => {
    console.log(domainHost)
    const days = time || 999
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    const expires = '; expires=' + date.toUTCString()
    document.cookie =
      name +
      namePrefix +
      `=${value};expires=${expires};path=/;domain=${domainHost ? domainHost : domain}`
    // 兼容官网 为了官网的登录态打通，需要讲token 与 groudid 写入cookie
    if (LOGIN_COOKIE.includes(name)) {
      document.cookie =
        OFFICAL_COOKIE_PREFIX +
        name +
        `=${value};expires=${expires};path=/;domain=${domainHost ? domainHost : OFFICAL_WEBSITE}`
    }
  },
  delete: (name: string) => {
    // 冗余删除
    document.cookie =
      name +
      `=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=${'.' + location.hostname}`
    document.cookie =
      name +
      `=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=${location.hostname}`

    // 兼容官网 为了官网的登录态打通，需要讲token 与 groudid 写入cookie
    if (LOGIN_COOKIE.includes(name)) {
      document.cookie =
        OFFICAL_COOKIE_PREFIX +
        name +
        `=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;domain=${OFFICAL_WEBSITE}`
    }

    // document.cookie = name  + namePrefix + `=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=${("." + location.hostname)}`;
    // document.cookie = name  + namePrefix + `=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=${location.hostname}`;
  }
}
