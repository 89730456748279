import { v4 as uuid } from 'uuid'
import { hasLogin } from '@/kerqu/kerquApi'
import { ComfyUIMessageType, sendMessageToParent } from '@/utils/kerquUtil'

export const OssFileType = {
  TEMP: 'TEMP',
  IMAGE: 'IMAGE',
  GROUP: 'GROUP',
  DOCUMENT: 'DOCUMENT',
  BATCH: 'BATCH'
}

interface ProofQuery {
  fileType: string
}
interface Proof {
  dir: string
  filename?: string
  accessId: string
  keepFileName?: boolean
  policy: string
  signature: string
  host: string
}

export interface UploadFileResponse {
  status: string
  url?: string
}
// 获取上传凭证
export const getUploadCredentials = async (query: ProofQuery) => {
  return fetch(`/api/oss/getToken?fileType=${query.fileType}`, {
    method: 'POST'
  }).then((res) => res.json())
}

// 文件上传函数
export const uploadFileToOSS: (
  files: File[],
  ossConfig: Proof
) => Promise<UploadFileResponse[]> = async (files, ossConfig) => {
  const uploadRequests = []
  for (let i = 0; i < files.length; i++) {
    const element = files[i]

    const formData = new FormData()
    let filename: string
    const fileExt = element.name.match(/\.[a-zA-Z0-9]+$/)
    if (ossConfig.filename) {
      const names = ossConfig.filename.split('/')
      filename = names[names.length - 1]
      if (!/\.[a-zA-Z0-9]+$/.test(filename)) {
        filename += fileExt
      }
    } else {
      filename = `${fileExt ? `[${fileExt.toString().substring(1)}]` : ''}${Date.now()}_${uuid()}${fileExt}`
    }

    formData.append('Filename', filename)
    formData.append('key', (ossConfig.dir + filename).replace(/^\//, ''))
    formData.append('OSSAccessKeyId', ossConfig.accessId)
    formData.append('policy', ossConfig.policy)
    formData.append('Signature', ossConfig.signature)
    formData.append('success_action_status', '200')
    formData.append('file', element)
    const uploadRequest = fetch(ossConfig.host, {
      method: 'POST',
      body: formData
    })
      .then(() => {
        return {
          status: 'done',
          url: ossConfig.dir + filename
        } as UploadFileResponse
      })
      .catch(
        (err) =>
          ({
            status: 'error'
          }) as UploadFileResponse
      )

    uploadRequests.push(uploadRequest)
  }
  // 使用Promise.all等待所有上传请求完成
  return Promise.all(uploadRequests)
}

const proofRequest: Record<
  string,
  { rs: (url: string) => void; rj: (err: Error) => void }[]
> = {}
// 存储四种上传凭证
export const getProof = async (query: ProofQuery) => {
  const ossKey = `oss-cred-${query.fileType}`
  const item = sessionStorage.getItem(ossKey)
  const proof = item ? JSON.parse(item) : null

  // 如果凭证不存在或已过期，则重新获取凭证并存储
  if (!proof || Date.now() >= proof.expire * 1000) {
    if (proofRequest[ossKey]) {
      return new Promise((resolve, reject) => {
        proofRequest[ossKey].push({ rs: resolve, rj: reject })
      })
    }
    proofRequest[ossKey] = []
    return new Promise((resolve) => {
      getUploadCredentials(query)
        .then((data) => {
          if (!data.data) {
            throw new Error(data.message)
          }
          sessionStorage.setItem(ossKey, JSON.stringify(data.data))
          proofRequest[ossKey].forEach(({ rs }) => rs(data.data))
          delete proofRequest[ossKey]
          resolve(data.data)
        })
        .catch((e) => {
          console.error(e)
          proofRequest[ossKey].forEach(({ rj }) => rj(e))
          delete proofRequest[ossKey]
        })
    })
  }

  // 如果凭证存在且未过期，则直接返回
  return proof
}

export const uploadTempFile = async (file: File, filename?: string) => {
  if (!hasLogin()) {
    sendMessageToParent({
      type: ComfyUIMessageType.LOGIN_REQUIRED,
      id: uuid(),
      payload: {}
    })
    throw new Error('Not Login')
  }
  const proof = await getProof({ fileType: OssFileType.TEMP })
  const [ossRes] = await uploadFileToOSS(
    [file],
    filename
      ? {
          ...proof,
          filename
        }
      : proof
  )
  return ossRes.url
}
